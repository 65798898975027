<template>
	<router-view/>
</template>

<style>
@import url('./assets/style.css');
</style>

<style>
body{
	background: #212121;
	font-family: 'Open Sans', open-sans, EmojiFontFace, helvetica, arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	height: 100%;
	padding: 0;
	margin: 0;
}
@font-face {
  font-family: 'icomoon';
  src:  url('./assets/fonts/icomoon.eot?fontv1');
  src:  url('./assets/fonts/icomoon.eot?fontv1#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon.ttf?fontv1') format('truetype'),
    url('./assets/fonts/icomoon.woff?fontv1') format('woff'),
    url('./assets/fonts/icomoon.svg?fontv1#lp2') format('svg');
  font-weight: normal;
  font-style: normal;
}

#nav a.router-link-exact-active {
	color: #42b983;
}
</style>
