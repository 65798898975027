import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c67d734a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { id: "filters" }
const _hoisted_3 = {
  key: 0,
  class: "title"
}
const _hoisted_4 = {
  key: 1,
  class: "title"
}
const _hoisted_5 = { id: "selectionFilterContainer" }
const _hoisted_6 = {
  key: 0,
  id: "epicContainer"
}
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationOptions = _resolveComponent("NavigationOptions")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_PTEpic = _resolveComponent("PTEpic")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.viewingProject())
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Please wait, loading PT data... "))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.currentProject().name), 1)),
      _createVNode(_component_NavigationOptions),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Multiselect, {
          modelValue: _ctx.showColumns,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showColumns) = $event)),
          placeholder: "Show Columns",
          class: "selectMultiMember",
          options: _ctx.availableColumns(),
          canClear: false,
          searchable: false,
          onSelect: _ctx.columnChange,
          onDeselect: _ctx.columnChange,
          ref: "inputColumns"
        }, null, 8, ["modelValue", "options", "onSelect", "onDeselect"]),
        _createVNode(_component_Multiselect, {
          modelValue: _ctx.selectedMembers,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedMembers) = $event)),
          placeholder: "Filter by People",
          class: "selectMultiMember",
          options: _ctx.availableMembers(),
          canClear: false,
          searchable: true,
          onSelect: _ctx.memberChange,
          onDeselect: _ctx.memberChange,
          ref: "input"
        }, null, 8, ["modelValue", "options", "onSelect", "onDeselect"]),
        _createVNode(_component_Multiselect, {
          modelValue: _ctx.selectedClients,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedClients) = $event)),
          placeholder: "Filter by Label",
          class: "selectMultiMember",
          options: _ctx.availableLabels(),
          canClear: false,
          searchable: true,
          onSelect: _ctx.labelChange,
          onDeselect: _ctx.labelChange,
          ref: "inputLabels"
        }, null, 8, ["modelValue", "options", "onSelect", "onDeselect"])
      ])
    ]),
    (_ctx.viewingProject())
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.showMyWorkColumn())
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                _createVNode(_component_PTEpic, {
                  name: "My Work - Start at the top!",
                  stories: _ctx.selectedMemberTickets(),
                  showEpic: true,
                  class: "myWork"
                }, null, 8, ["stories"])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentProject().epicLabels, (epic, index) => {
            return (_openBlock(), _createBlock(_component_PTEpic, {
              key: index,
              name: epic,
              showEpic: false,
              stories: _ctx.currentProject().epics[epic]
            }, null, 8, ["name", "stories"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}