import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-166db442"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "projectSelector" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = {
  key: 0,
  class: "throbber icon-cog"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectStats = _resolveComponent("ProjectStats")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(this.project.name) + " ", 1),
      _createElementVNode("div", {
        class: "selectProject",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectProject(this.project.projectId)))
      }, "View Project")
    ]),
    (!_ctx.gotData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_ProjectStats, { stats: _ctx.projectData }, null, 8, ["stats"])
        ]))
  ]))
}