import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Project from '../views/ViewProject.vue';
import SwitchProject from '../views/ViewSwitchProject.vue';
import Reports from '../views/ViewReports.vue';
import Auth from '../views/ViewAuth.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Home',
		component: Auth,
		meta: {
			requiresAuth: false,
			guestsOnly: true
		}
	},
	{
		path: '/logout',
		name: 'Logout',
		component: Auth,
		meta: {
			requiresAuth: false,
			removeAuth: true
		}
	},
	{
		path: '/switch',
		name: 'Switch',
		component: SwitchProject,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/reports',
		name: 'Reports',
		component: Reports,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/reports/view/:filename',
		name: 'ViewReports',
		component: Reports,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/project/:projectId/person/:name',
		name: 'ProjectWithName',
		component: Project,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/project/:projectId',
		name: 'Project',
		component: Project,
		meta: {
			requiresAuth: true
		}
	}
	/*,
	{
		path: '/:catchAll(.*)',
		name: 'CatchAll',
		component: SwitchProject,
		meta: {
			requiresAuth: true
		}
	} */
];

const router = createRouter({
	history: createWebHistory(), // was process.env.BASE_URL but this is long gone
	routes
});

router.beforeEach((to, from, next) =>
{
	if(to.matched.some((record) => record.meta.removeAuth))
	{
		// page forces logout
		localStorage.clear();
	}

	// do we need auth?
	if(to.matched.some((record) => record.meta.requiresAuth))
	{
		// yes, we do
		// TODO localstorage is not good for JWT, shift to memory later. Good enough for testing/first round PoC.
		if(localStorage.getItem('th_jwt') == null)
		{
			// but we don't have it, push user to auth page
			next({ path: '/', params: { nextUrl: to.fullPath } });
		}
		else
		{
			// you may proceed, the jwt validity is confirmed with any api request
			next();
		}
	}
	else if(to.matched.some((record) => record.meta.guestsOnly))
	{
		if(localStorage.getItem('th_jwt') != null)
		{
			// we have a JWT, we're like a 10yr industry vet applying for junior role
			// overqualified, you're not getting this page, go somewhere more suitable
			next({ path: '/switch' });
		}
		else
		{
			// you may proceed
			next();
		}
	}
	else
	{
		// auth not needed, carry on
		next();
	}
});

export default router;
