import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a7d4396"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = {
  key: 1,
  class: "rightLine"
}
const _hoisted_3 = { class: "rightLine" }
const _hoisted_4 = { class: "rightLine" }
const _hoisted_5 = { class: "rightLine" }
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(['stage-stats-container', _ctx.colourLineAccordingToEntryName])
  }, [
    (_ctx.epicsThisRepresents)
      ? (_openBlock(), _createElementBlock("td", {
          key: 0,
          class: "stageName rightLine",
          title: _ctx.epicsThisRepresents
        }, _toDisplayString(_ctx.entryName), 9, _hoisted_1))
      : (_openBlock(), _createElementBlock("td", _hoisted_2, _toDisplayString(_ctx.entryName), 1)),
    _createElementVNode("td", null, _toDisplayString(_ctx.entry.feature[this.col1Prop] + _ctx.entry.bug[this.col1Prop] + _ctx.entry.chore[this.col1Prop]), 1),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.entry.feature[this.col2Prop] + _ctx.entry.bug[this.col2Prop] + _ctx.entry.chore[this.col2Prop]), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.entry.feature[this.col1Prop]), 1),
    _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.entry.feature[this.col2Prop]), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.entry.bug[this.col1Prop]), 1),
    _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.entry.bug[this.col2Prop]), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.entry.chore[this.col1Prop]), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.entry.chore[this.col2Prop]), 1),
    (_ctx.extraColumn)
      ? (_openBlock(), _createElementBlock("td", _hoisted_6, _toDisplayString(_ctx.extraColumn), 1))
      : _createCommentVNode("", true)
  ], 2))
}