
import { defineComponent } from 'vue';
// import ProjectMember from '@/components/ProjectMember.vue';
import ProjectStatsTableEntry from '@/components/ProjectStatsTableEntry.vue';
import { IObject, StatsObject } from '@/types';

export default defineComponent({
	name: 'ProjectStats',
	components: {
		ProjectStatsTableEntry
	},
	props: {
		stats: {
			type: Object as () => StatsObject,
			required: true
		}
	},
	methods: {
		epicsAtThisStage(stageName: string)
		{
			const epics = [] as Array<string>;

			Object.entries(this.stats.epicLabelsToStatsGroup).filter(([, epicLabel]) =>
			{
				return epicLabel === stageName;
			}).forEach((epicAndLabel) =>
			{
				epics.push(epicAndLabel[0]);
			});

			return epics;
		},
		ownersWithTickets()
		{
			const owners = {} as IObject;

			// make object into array for filtering
			Object.entries(this.stats.owners).filter(([, owner]) =>
			{
				// filter the array to find only members with actual tickets in flight
				return owner.feature.count + owner.bug.count + owner.chore.count > 0;
			}
			).forEach((ownerWithTicket) =>
			{
				// now push back to object form that the rest of the UI expects
				owners[ownerWithTicket[0]] = ownerWithTicket[1];
			});

			return owners;
		}
	},
	mounted()
	{
		// placeholder
		// console.log(this.stats);
	}
});
