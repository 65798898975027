
import { defineComponent } from 'vue';
import axios from 'axios';
import VueCal from 'vue-cal';
// eslint-disable-next-line
import 'vue-cal/dist/vue-cal.css';
import { sub, formatISO, format, eachDayOfInterval } from 'date-fns';
import { IObject } from '@/types';
import store from '@/store/store';

export default defineComponent({
	name: 'ProjectRandDStatsReports',
	components: {
		VueCal
	},
	data()
	{
		return {
			// in next 2 date calls, new Date(new Date().setHour()) is needed
			// to return Date object in a one liner having called Date.setHours

			// from = start date, set to midnight so we include the full day
			selectedDateFrom: sub(new Date(new Date().setHours(0, 0, 0, 0)), { months: 1 }),	// initial selected dates = last 1 month
			// to = until date, set to 23:59:59 so we include the full day until that time
			selectedDateTo: new Date(new Date().setHours(23, 59, 59, 0)),
			report: {} as IObject,
			showThrobber: false,
			generatingReport: '',
			reportList: [] as Array<IObject>,
			reportRefresh: 0 as number,
			progressPercentage: 0 as number
		};
	},
	computed: {
		progressWidth(): string
		{
			return `width:${this.progressPercentage}%`;
		},
		fromMinDate(): Date
		{
			// can't just have fromMaxDate on first cal
			// fromMaxDate only works if fromMinDate also set... :rolleyes:
			// earliest PT project (AV7) in use here started 14th Aug 2017
			return new Date(new Date('August 14, 2017').setHours(0, 0, 0, 0));
		},
		fromMaxDate(): Date
		{
			return this.selectedDateTo;		// from max is the date selected by the "to" selector
		},
		untilMinDate(): Date
		{
			return this.selectedDateFrom;	// to min is the date selected by the "from" selector
		},
		untilMaxDate(): Date
		{
			return new Date(new Date().setHours(23, 59, 59, 0));	// can't go into the future, limit "to" to end of today
		},
		reportListParsed(): Array<IObject>
		{
			if(!this.reportList)
			{
				return [];
			}

			const parsedResult = [] as Array<IObject>;

			this.reportList.forEach((fileObject) =>
			{
				parsedResult.push(this.parseFileName(fileObject));
			});

			return parsedResult;
		}
	},
	methods: {
		parseFileName(fileObject: IObject): IObject
		{
			const fileNameParts = fileObject.file.split('.')[0].split('_');

			const from = new Date(parseInt(fileNameParts[0]));

			const to = new Date(parseInt(fileNameParts[1]));

			const duration = eachDayOfInterval({ start: from, end: to }).length;

			return {
				name: fileObject.file,
				reportCreated: format(new Date(fileObject.time), 'EEE MMM dd yyyy @ H:mm:ss'),
				from: format(from, 'EEE MMM dd yyyy'),
				to: format(to, 'EEE MMM dd yyyy'),
				duration
			};
		},
		selectedDateFromChange(dateSelected:Date)
		{
			this.selectedDateFrom = dateSelected;
		},
		selectedDateToChange(dateSelected:Date)
		{
			this.selectedDateTo = dateSelected;
		},
		getReport()
		{
			this.showThrobber = true;

			axios.get(`${process.env.VUE_APP_SRV_URL}teamstats/generate/${formatISO(this.selectedDateFrom)}/${formatISO(this.selectedDateTo)}`)
				.then((response:IObject) =>
				{
					if(!response.data.success)
					{
						this.showThrobber = false;
					}
					else
					{
						// the throbber will only dissappear when we have a report here with the name we've been told to expect
						this.generatingReport = response.data.reportName;

						this.progressCheck();
					}
				});
		},
		progressCheck()
		{
			axios.get(`${process.env.VUE_APP_SRV_URL}teamstats/progress/${this.generatingReport}`)
				.then((response:IObject) =>
				{
					if(!response.data.percentage)
					{
						response.data.percentage = 0;
					}

					this.progressPercentage = Math.min(Math.max(parseInt(response.data.percentage), 0), 100);

					if(this.progressPercentage === 100)
					{
						// done!
						this.getReportList();
					}
					else
					{
						setTimeout(() =>
						{
							this.progressCheck();
						}, 750);
					}
				});
		},
		getReportList()
		{
			// if we've been called by a new report, we don't want to have lots of these, cancel what we've got going
			clearTimeout(this.reportRefresh);

			axios.get(`${process.env.VUE_APP_SRV_URL}teamstats/reports`)
				.then((response) =>
				{
					this.reportList = response.data;

					const requestedFilenameFromURL = this.$route.params.filename;

					if(requestedFilenameFromURL)
					{
						if(store.state.reportMeta && store.state.reportMeta.name !== requestedFilenameFromURL)
						{
							// we're not viewing the file we're supposed to be, does it exist?
							this.reportList.forEach((reportEntry) =>
							{
								if(reportEntry.file === requestedFilenameFromURL && typeof requestedFilenameFromURL === 'string')
								{
									this.viewReport(requestedFilenameFromURL, this.parseFileName(reportEntry));
								}
							});
						}
					}

					// has a report we're waiting for finished?
					if(this.generatingReport && this.showThrobber)
					{
						this.reportList.forEach((reportEntry) =>
						{
							if(reportEntry.file === this.generatingReport)
							{
								this.generatingReport = '';
								this.progressPercentage = 0;
								this.showThrobber = false;
							}
						});
					}

					// once started, we do this repeatedly
					let interval = 300 * 1000;	// 5 minutes by default

					if(this.showThrobber)
					{
						interval = 5 * 1000;	// 5 seconds if we're waiting on a report
					}

					this.reportRefresh = setTimeout(() =>
					{
						this.getReportList();
					}, interval);
				});
		},
		deleteReport(filename: string)
		{
			axios.get(`${process.env.VUE_APP_SRV_URL}teamstats/delete/${filename}`)
				.then((response) =>
				{
					if(response.data.error)
					{
						// error occurred, dont do anything
						console.log(response.data.error);
					}
					else
					{
						// refresh report list
						this.getReportList();
					}
				});
		},
		viewReport(filename: string, fileObject: IObject)
		{
			axios.get(`${process.env.VUE_APP_SRV_URL}teamstats/view/${filename}`)
				.then((response) =>
				{
					if(response.data.error)
					{
						// error occurred, dont do anything
						console.log(response.data.error);
					}
					else
					{
						store.commit('VIEW_REPORT', response.data);
						store.commit('REPORT_META', fileObject);

						console.log(response.data);

						this.$router.push(`/reports/view/${filename}`);
					}
				});
		}
	},
	mounted()
	{
		// console.log('PROJ', this.project);
		this.getReportList();
	}
});
