
import { defineComponent, PropType } from 'vue';
import PTStory from '@/components/PTStory.vue';
import { IObject } from '@/types';
import store from '@/store/store';

export default defineComponent({
	name: 'PTEpic',
	components: {
		PTStory
	},
	props: {
		name: {
			type: String,
			required: true,
			default: ''
		},
		stories: {
			type: Array as PropType<IObject[]>,
			required: true
		},
		showEpic: {
			type: Boolean,
			default: false
		},
		class: {
			type: String,
			default: ''
		}
	},
	methods: {
		overrideClass():string
		{
			if(this.class)
			{
				return this.class;
			}

			const [category] = this.name.split(' ');

			switch(category)
			{
				case 'priorities': // a9d08e
				case 'cst':
					return 'client';
				case 'dev':
					return 'dev';
				case 'qa':
					return 'qa';
				case 'design':
					return 'design';
			}

			return '';
		},
		epicUrl():string
		{
			// deprecated from template but left here
			// PT doesn't really show you the Epic but rather the best I could do was a search
			// that did... except every time you clicked a link like this you'd end up with another
			// search window... urgh.
			return `https://www.pivotaltracker.com/n/projects/${store.state.viewingProjectId}/search?q=epic:"${this.name}"`;
		},
		filteredStories(): Array<IObject>
		{
			if(store.state.filterByMember.length === 0 && store.state.filterByClient.length === 0)
			{
				return this.stories;
			}

			const storiesToDisplay:Array<IObject> = [];

			for(const s in this.stories)
			{
				let toAddMember = true, toAddClient = true;

				if(store.state.filterByMember.length > 0)
				{
					toAddMember = false;

					for(const m in store.state.filterByMember)
					{
						if(this.stories[s].owner_ids.indexOf(store.state.filterByMember[m]) >= 0)
						{
							toAddMember = true;
							break;
						}
					}
				}

				if(store.state.filterByClient.length > 0)
				{
					toAddClient = false;

					for(const c in store.state.filterByClient)
					{
						for(const l in this.stories[s].labels)
						{
							if(this.stories[s].labels[l].kind === 'label' && this.stories[s].labels[l].name === store.state.filterByClient[c])
							{
								toAddClient = true;
							}
						}
					}
				}

				if(toAddMember && toAddClient)
				{
					storiesToDisplay.push(this.stories[s]);
				}
			}

			return storiesToDisplay;
		},
		storyCount(): number
		{
			return this.filteredStories().length;
		},
		pointCount(): number
		{
			const count = this.filteredStories().reduce((acc, story) =>
			{
				if(story.estimate)
				{
					acc += parseInt(story.estimate, 10);
				}

				return acc;
			}, 0);

			return count;
		}
	},
	computed: {
		isEmpty(): boolean
		{
			if(this.filteredStories().length === 0)
			{
				return true;
			}

			if(store.state.showColumns && store.state.showColumns !== 'all')
			{
				// then we're filtering columns, lets make the ones we don't want go away
				const firstThreeLetters = this.name.substring(0, 3);

				switch(firstThreeLetters)
				{
					case 'des': // ign
						if(store.state.showColumns !== 'design')
						{
							return true;
						}

						break;
					case 'qa ':
						if(store.state.showColumns !== 'qa')
						{
							return true;
						}

						break;
					case 'dev':
						if(store.state.showColumns !== 'dev')
						{
							return true;
						}

						break;
					case 'pri': // orities
						if(store.state.showColumns !== 'cst')
						{
							return true;
						}

						break;
					case 'cst': // orities
						if(store.state.showColumns !== 'cst')
						{
							return true;
						}

						break;
				}
			}

			return false;
		}
	},
	mounted()
	{
		// console.log('MOUNT', this.epic);
	}
});
