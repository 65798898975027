
import { defineComponent } from 'vue';
import axios from 'axios';
import PTEpic from '@/components/PTEpic.vue';
import store from '@/store/store';
import Multiselect from '@vueform/multiselect';
import NavigationOptions from '@/components/NavigationOptions.vue';
import { IObject } from '@/types';

export default defineComponent({
	name: 'ViewProject',
	components: {
		PTEpic,
		Multiselect,
		NavigationOptions
	},
	beforeRouteLeave(to, from, next)
	{
		if(to.params.projectId && parseInt(to.params.projectId.toString(), 10))
		{
			this.getPtData(parseInt(to.params.projectId.toString(), 10));
		}

		next();
	},
	data()
	{
		return {
			selectedMembers: null,
			selectedClients: null,
			showColumns: 'all'
		};
	},
	methods: {
		async getPtData(projectId: number)
		{
			if(store.state.viewingProjectId !== projectId)
			{
				store.commit('VIEW_CACHE', 0);
			}

			const response = await axios.get(`${process.env.VUE_APP_SRV_URL}project/${projectId}`);

			store.commit('STORE_CACHE', { projectId, store: response.data });

			if(store.state.viewingProjectId !== projectId)
			{
				store.commit('VIEW_CACHE', projectId);

				const curProj = store.state.projectCaches[store.state.viewingProjectId];

				if(this.$route.params.name)
				{
					const stringPeople = '' + this.$route.params.name;	// cast LocationQueryValue to string

					const people = stringPeople.split(',');

					for(const p in people)
					{
						for(const m in curProj.members)
						{
							if(curProj.members[m].initials === people[p])
							{
								// any needed here as TS/Vue docs on how to get a type for refs to work didn't work
								// eslint-disable-next-line
								(this.$refs.input as any).select(
									{ value: curProj.members[m].id }
								);
							}
						}
					}
				}

				if(this.$route.query.labels)
				{
					const stringLabels = '' + this.$route.query.labels;

					const labels = stringLabels.split(',');

					for(const l in labels)
					{
						if(curProj.labels.indexOf(labels[l]) >= 0)
						{
							// any needed here as TS/Vue docs on how to get a type for refs to work didn't work
							// eslint-disable-next-line
							(this.$refs.inputLabels as any).select(
								{ value: labels[l] }
							);
						}
					}
				}
			}

			return response;
		},
		availableLabels()
		{
			if(!store.state.viewingProjectId)
			{
				return [];
			}

			const labels = [];

			const curProj = store.state.projectCaches[store.state.viewingProjectId];

			for(const l in curProj.labels)
			{
				labels.push({
					value: curProj.labels[l],
					label: curProj.labels[l]
				});
			}

			return labels;
		},
		availableMembers()
		{
			if(!store.state.viewingProjectId)
			{
				return [];
			}

			const members = [];

			const curProj = store.state.projectCaches[store.state.viewingProjectId];

			for(const m in curProj.members)
			{
				members.push({
					value: curProj.members[m].id,
					label: `${curProj.members[m].name} (${curProj.members[m].initials})`
				});
			}

			return members;
		},
		availableColumns()
		{
			return [
				{ value: 'all', label: 'All' },
				{ value: 'dev', label: 'DEV' },
				{ value: 'cst', label: 'CST' },
				{ value: 'qa', label: 'QA' },
				{ value: 'design', label: 'Design' }
			];
		},
		viewingProject(): boolean
		{
			if(store.state.viewingProjectId === 0)
			{
				return false;
			}

			return true;
		},
		showMyWorkColumn(): boolean
		{
			// currently limiting to show only if one person is selected
			// but selectedMemberTickets below already supports as many members
			// could easily have this enable if length > 0 for example
			if(store.state.filterByMember.length === 1)
			{
				return true;
			}

			return false;
		},
		selectedMemberTickets()
		{
			const storiesToDisplay:Array<IObject> = [];

			const allEpics = this.currentProject().epics;

			for(const e in allEpics)
			{
				for(const s in allEpics[e])
				{
					let toAddMember = true, toAddClient = true;

					if(store.state.filterByMember.length > 0)
					{
						toAddMember = false;

						for(const m in store.state.filterByMember)
						{
							if(allEpics[e][s].owner_ids.indexOf(store.state.filterByMember[m]) >= 0)
							{
								toAddMember = true;
								break;
							}
						}
					}

					if(store.state.filterByClient.length > 0)
					{
						toAddClient = false;

						for(const c in store.state.filterByClient)
						{
							for(const l in allEpics[e][s].labels)
							{
								if(allEpics[e][s].labels[l].kind === 'label' && allEpics[e][s].labels[l].name === store.state.filterByClient[c])
								{
									toAddClient = true;
								}
							}
						}
					}

					if(toAddMember && toAddClient)
					{
						storiesToDisplay.push(allEpics[e][s]);
					}
				}
			}

			return storiesToDisplay.reverse();
		},
		currentProject()
		{
			if(!store.state.viewingProjectId)
			{
				return {};
			}

			return store.state.projectCaches[store.state.viewingProjectId];
		},
		memberChange()
		{
			if(!this.selectedMembers)
			{
				store.commit('LIMIT_BY_MEMBERS', []);
			}
			else
			{
				store.commit('LIMIT_BY_MEMBERS', [this.selectedMembers]);
			}
		},
		labelChange()
		{
			if(!this.selectedClients)
			{
				store.commit('LIMIT_BY_CLIENTS', []);
			}
			else
			{
				store.commit('LIMIT_BY_CLIENTS', [this.selectedClients]);
			}
		},
		columnChange()
		{
			store.commit('SHOW_COLUMNS', this.showColumns);
		}
	},
	async mounted()
	{
		if(!this.$route.params.projectId)
		{
			// default project, ACB
			this.$router.push('/switch');
		}
		else
		{
			this.getPtData(parseInt(this.$route.params.projectId.toString(), 10));
		}
	}
});
