
import { defineComponent } from 'vue';
import store from '@/store/store';
import { IObject, ReportObject } from '@/types';
import ProjectStatsTableEntry from '@/components/ProjectStatsTableEntry.vue';

export default defineComponent({
	name: 'ProjectRandDStatsReportReviews',
	components: {
		ProjectStatsTableEntry
	},
	props: {
		team: {
			type: Object,
			required: true
		},
		title: {
			type: String,
			required: true
		},
		reviewType: {
			type: String,
			required: true
		}
	},
	computed: {
		ticketsReviewed(): number
		{
			return this.report.reviews[this.reviewType].feature.ticketsWithReview + this.report.reviews[this.reviewType].bug.ticketsWithReview + this.report.reviews[this.reviewType].chore.ticketsWithReview;
		},
		passesGiven(): number
		{
			return this.report.reviews[this.reviewType].feature.pass + this.report.reviews[this.reviewType].bug.pass + this.report.reviews[this.reviewType].chore.pass;
		},
		areWeViewingAReport(): boolean
		{
			if(store.state.report && store.state.report.members && Object.keys(store.state.report.members).length > 0)
			{
				return true;
			}

			return false;
		},
		reportMeta(): IObject
		{
			if(store.state.reportMeta)
			{
				return store.state.reportMeta;
			}

			return {};
		},
		report(): ReportObject|IObject
		{
			if(store.state.report && store.state.report.members && Object.keys(store.state.report.members).length > 0)
			{
				return store.state.report;
			}

			return {};
		}
	},
	methods: {
		// placeholder
		reviewPassRate(memberReviews:IObject): string
		{
			const totalTicketsReviewed = memberReviews.feature.pass + memberReviews.bug.pass + memberReviews.chore.pass;

			// counting all dev work as R&D + x% of bug fix points
			const totalReviews = memberReviews.feature.pass + memberReviews.feature.revise +
				memberReviews.bug.pass + memberReviews.bug.revise +
				memberReviews.chore.pass + memberReviews.chore.revise;

			return `${Math.round((totalTicketsReviewed / totalReviews) * 1000) / 10}%`;
		}
	},
	mounted()
	{
		// console.log('REVIEWERS', this.reviewType, this.team);
	}
});
