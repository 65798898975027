
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'ProjectStatsTableEntry',
	components: {
	},
	props: {
		entry: {
			type: Object,
			required: true
		},
		entryName: {
			type: String,
			required: false
		},
		epicsThatThisStageRepresents: {
			type: Array,
			required: false
		},
		col1Prop: {
			type: String,
			required: false,
			default: 'count'
		},
		col2Prop: {
			type: String,
			required: false,
			default: 'points'
		},
		extraColumn: {
			type: [String, Number],
			required: false,
			default: ''
		}
	},
	computed: {
		epicsThisRepresents(): string
		{
			if(this.epicsThatThisStageRepresents)
			{
				return this.epicsThatThisStageRepresents.join(', ');
			}

			return '';
		},
		colourLineAccordingToEntryName(): string
		{
			if(this.entryName)
			{
				switch(this.entryName)
				{
					case 'Active Dev':
					case 'Docs Needed':
					case 'Awaiting Deploy':
					case 'Awaiting Dev':
						return 'dev';
					case 'With Design':
						return 'design';
					case 'With QA':
						return 'qa';
					case 'Awaiting Priority':
					case 'Live (Final Actions)':
						return 'cst';
				}
			}

			return 'default';
		}
	},
	mounted()
	{
		// placeholder
		// console.log('stage', this.stageName, this.stage);
	}
});
