
import { defineComponent } from 'vue';
import axios from 'axios';
import store from '@/store/store';
import { IObject, ReportObject } from '@/types';
import NavigationOptions from '@/components/NavigationOptions.vue';
import ProjectRandDStatsReports from '@/components/ProjectRandDStatsReports.vue';
import ProjectRandDStatsReportGlobal from '@/components/ProjectRandDStatsReportGlobal.vue';
import ProjectRandDStatsReportTeam from '@/components/ProjectRandDStatsReportTeam.vue';
import ProjectRandDStatsReportReviews from '@/components/ProjectRandDStatsReportReviews.vue';

export default defineComponent({
	name: 'ViewHome',
	components: {
		NavigationOptions,
		ProjectRandDStatsReports,
		ProjectRandDStatsReportGlobal,
		ProjectRandDStatsReportTeam,
		ProjectRandDStatsReportReviews
	},
	beforeRouteLeave(to, from, next)
	{
		if(to.params.projectId && parseInt(to.params.projectId.toString(), 10))
		{
			this.getPtData(parseInt(to.params.projectId.toString(), 10));
		}

		next();
	},
	data()
	{
		return {
			selectedMembers: null,
			selectedClients: null,
			projectList: null
		};
	},
	computed: {
		report(): ReportObject
		{
			if(store.state.report && store.state.report.members && Object.keys(store.state.report.members).length > 0)
			{
				return store.state.report;
			}

			return { version: 0 };
		},
		filterOwners(): Array<IObject>
		{
			const owners = [] as Array<IObject>;

			if(this.report.members)
			{
				Object.values(this.report.members).forEach((member) =>
				{
					if(member.tickets.feature.points > 0 || member.tickets.bug.points > 0 || member.tickets.chore.points > 0)
					{
						// this person is down as a ticket owner for something, somewhere
						owners.push(member);
					}
				});
			}

			return owners;
		},
		areWeViewingAReport(): boolean
		{
			if(store.state.report && store.state.report.members && Object.keys(store.state.report.members).length > 0)
			{
				return true;
			}

			return false;
		}
	},
	methods: {
		filterReviewType(reviewType:string): Array<IObject>
		{
			const owners = [] as Array<IObject>;

			if(this.report.members)
			{
				Object.values(this.report.members).forEach((member) =>
				{
					if(member.reviewer[reviewType].feature.devPoints > 0 || member.reviewer[reviewType].bug.devPoints > 0 || member.reviewer[reviewType].chore.devPoints > 0)
					{
						owners.push(member);
					}
				});
			}

			// console.log('REVIEWS', owners);

			return owners;
		},
		async getPtData(projectId: number)
		{
			const response = await axios.get(`${process.env.VUE_APP_SRV_URL}project/${projectId}`);

			store.commit('STORE_CACHE', { projectId, store: response.data });

			if(!store.state.viewingProjectId)
			{
				store.commit('VIEW_CACHE', projectId);

				const curProj = store.state.projectCaches[store.state.viewingProjectId];

				if(this.$route.query.people)
				{
					const stringPeople = '' + this.$route.query.people;	// cast LocationQueryValue to string

					const people = stringPeople.split(',');

					for(const p in people)
					{
						for(const m in curProj.members)
						{
							if(curProj.members[m].initials === people[p])
							{
								// any needed here as TS/Vue docs on how to get a type for refs to work didn't work
								// eslint-disable-next-line
								(this.$refs.input as any).select(
									{ value: curProj.members[m].id }
								);
							}
						}
					}
				}

				if(this.$route.query.labels)
				{
					const stringLabels = '' + this.$route.query.labels;

					const labels = stringLabels.split(',');

					for(const l in labels)
					{
						if(curProj.labels.indexOf(labels[l]) >= 0)
						{
							// any needed here as TS/Vue docs on how to get a type for refs to work didn't work
							// eslint-disable-next-line
							(this.$refs.inputLabels as any).select(
								{ value: labels[l] }
							);
						}
					}
				}
			}

			return response;
		},
		availableLabels()
		{
			if(!store.state.viewingProjectId)
			{
				return [];
			}

			const labels = [];

			const curProj = store.state.projectCaches[store.state.viewingProjectId];

			for(const l in curProj.labels)
			{
				labels.push({
					value: curProj.labels[l],
					label: curProj.labels[l]
				});
			}

			return labels;
		},
		availableMembers()
		{
			if(!store.state.viewingProjectId)
			{
				return [];
			}

			const members = [];

			const curProj = store.state.projectCaches[store.state.viewingProjectId];

			for(const m in curProj.members)
			{
				members.push({
					value: curProj.members[m].id,
					label: curProj.members[m].name
				});
			}

			return members;
		},
		viewingProject(): boolean
		{
			if(store.state.viewingProjectId === 0)
			{
				return false;
			}

			return true;
		},
		showMyWorkColumn(): boolean
		{
			// currently limiting to show only if one person is selected
			// but selectedMemberTickets below already supports as many members
			// could easily have this enable if length > 0 for example
			if(store.state.filterByMember.length === 1)
			{
				return true;
			}

			return false;
		},
		selectedMemberTickets()
		{
			const storiesToDisplay:Array<IObject> = [];

			const allEpics = this.currentProject().epics;

			for(const e in allEpics)
			{
				for(const s in allEpics[e])
				{
					let toAddMember = true, toAddClient = true;

					if(store.state.filterByMember.length > 0)
					{
						toAddMember = false;

						for(const m in store.state.filterByMember)
						{
							if(allEpics[e][s].owner_ids.indexOf(store.state.filterByMember[m]) >= 0)
							{
								toAddMember = true;
								break;
							}
						}
					}

					if(store.state.filterByClient.length > 0)
					{
						toAddClient = false;

						for(const c in store.state.filterByClient)
						{
							for(const l in allEpics[e][s].labels)
							{
								if(allEpics[e][s].labels[l].kind === 'label' && allEpics[e][s].labels[l].name === store.state.filterByClient[c])
								{
									toAddClient = true;
								}
							}
						}
					}

					if(toAddMember && toAddClient)
					{
						storiesToDisplay.push(allEpics[e][s]);
					}
				}
			}

			return storiesToDisplay.reverse();
		},
		currentProject()
		{
			if(!store.state.viewingProjectId)
			{
				return {};
			}

			return store.state.projectCaches[store.state.viewingProjectId];
		},
		multiMemberChange()
		{
			store.commit('LIMIT_BY_MEMBERS', this.selectedMembers);
		},
		multiClientChange()
		{
			store.commit('LIMIT_BY_CLIENTS', this.selectedClients);
		}
	},
	async mounted()
	{
		// console.log('Selecting New Project To View');

		const projects = await axios.get(`${process.env.VUE_APP_SRV_URL}projects`);

		this.projectList = projects.data;

		// console.log('PROJECT LIST', this.projectList);
	}
});
