import { createStore } from 'vuex';
import { CacheStorageObject, IObject, ReportObject } from '@/types';

export default createStore({
	state: {
		projectCaches: {} as IObject,
		viewingProjectId: 0 as number,
		filterByMember: [],
		filterByClient: [],
		showColumns: 'all' as string,
		report: {} as ReportObject,
		reportMeta: {} as IObject
	},
	mutations: {
		STORE_CACHE(state, cache:CacheStorageObject)
		{
			state.projectCaches[cache.projectId] = cache.store;
		},
		VIEW_CACHE(state, viewProjectId: number)
		{
			state.viewingProjectId = viewProjectId;
		},
		LIMIT_BY_MEMBERS(state, memberArray: [])
		{
			state.filterByMember = memberArray;
		},
		LIMIT_BY_CLIENTS(state, clientArray: [])
		{
			state.filterByClient = clientArray;
		},
		SHOW_COLUMNS(state, columns: string)
		{
			state.showColumns = columns;
		},
		VIEW_REPORT(state, report: ReportObject)
		{
			state.report = report;
		},
		REPORT_META(state, meta: IObject)
		{
			state.reportMeta = meta;
		}
	},
	actions: {
	},
	modules: {
	}
});
