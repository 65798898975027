
import { defineComponent } from 'vue';
import store from '@/store/store';

export default defineComponent({
	name: 'PTStory',
	components: {
	},
	props: {
		story: {
			type: Object,
			required: true
		},
		showEpic: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		processEpic()
		{
			// get the one epic this ticket belongs to as part of the process
			// ignore all other labels
			for(const l in this.story.labels)
			{
				if(store.state.projectCaches[store.state.viewingProjectId].epicLabels.indexOf(this.story.labels[l].name) >= 0)
				{
					return this.story.labels[l].name;
				}
			}

			return '';
		},
		openInPT()
		{
			window.open(`https://www.pivotaltracker.com/n/projects/${store.state.viewingProjectId}/stories/${this.story.id}`);
		},
		mapStateToButtonAction(state:string):string
		{
			switch(state)
			{
				case 'delivered':
					return 'Accept';
				case 'unscheduled':
					return 'Start';
				case 'finished':
					return 'Deliver';
				case 'started':
					return 'Finish';
				default:
					return state;
			}
		},
		estimateLabel(estimate:number):string
		{
			if(isNaN(estimate))
			{
				return '?';
			}

			return estimate.toString();
		},
		owners()
		{
			if(this.story.owner_ids && this.story.owner_ids.length > 0)
			{
				const str = [];

				for(const o in this.story.owner_ids)
				{
					if(!store.state.projectCaches[store.state.viewingProjectId].members[this.story.owner_ids[o]])
					{
						// member no longer part of our project
						str.push('MIA');
					}
					else
					{
						str.push(store.state.projectCaches[store.state.viewingProjectId].members[this.story.owner_ids[o]].initials);
					}
				}

				return `(${str.join(',')})`;
			}

			return '';
		}
	},
	mounted()
	{
		// console.log('STORY', this.story);
	}
});
