
import { defineComponent } from 'vue';
import store from '@/store/store';
import { IObject, ReportObject } from '@/types';

export default defineComponent({
	name: 'ProjectRandDStatsReport',
	components: {
	},
	computed: {
		areWeViewingAReport(): boolean
		{
			if(store.state.report && store.state.report.members && Object.keys(store.state.report.members).length > 0)
			{
				return true;
			}

			return false;
		},
		reportVersion(): number
		{
			if(this.areWeViewingAReport)
			{
				return store.state.report.version;
			}

			return 0;
		},
		reportMeta(): IObject
		{
			if(store.state.reportMeta)
			{
				return store.state.reportMeta;
			}

			return {};
		},
		report(): ReportObject|IObject
		{
			if(store.state.report && store.state.report.members && Object.keys(store.state.report.members).length > 0)
			{
				return store.state.report;
			}

			return {};
		}
	},
	methods: {
		// placeholder,
		average(number:number, count:number): string
		{
			if(count > 0)
			{
				return `${Math.round((number / count) * 100) / 100}`;
			}

			return '~';
		}
	},
	mounted()
	{
		// console.log('PROJ', this.project);
		// console.log('REPORT', store.state.report);
	}
});
