
import { defineComponent } from 'vue';
import axios from 'axios';
import ProjectStats from '@/components/ProjectStats.vue';

export default defineComponent({
	name: 'PTProject',
	components: {
		ProjectStats
	},
	data()
	{
		return {
			gotData: false,
			projectData: {}
		};
	},
	props: {
		project: {
			type: Object,
			required: true
		}
	},
	methods: {
		selectProject(projectId:number)
		{
			this.$router.push(`/project/${projectId}`);
		}
	},
	mounted()
	{
		// console.log('PROJ', this.project);
		axios.get(`${process.env.VUE_APP_SRV_URL}project/${this.project.projectId}/stats`)
			.then((response) =>
			{
				this.gotData = true;

				this.projectData = response.data;
			});
	}
});
