
import { defineComponent } from 'vue';
import axios from 'axios';
import { IObject } from '@/types';

export default defineComponent({
	name: 'ViewAuth',
	components: {
	},
	data: () =>
	{
		return {
			loginButton: 'Login',
			username: '',
			password: '',
			loginFail: '',
			regSuccess: false
		};
	},
	computed: {
	},
	methods: {
		async login()
		{
			let response = { err: true, success: false, reason: '', data: {} as IObject };

			this.loginButton = 'Authenticating with LP2...';

			try
			{
				response = await axios.post(`${process.env.VUE_APP_LP2_MAIN_URL}/knockknock`, { username: this.username, password: this.password });
			}
			catch(e)
			{
				this.loginFail = 'Login not available at this time';
				this.loginButton = 'Login';
			}

			if(response.data.success)
			{
				localStorage.setItem('th_jwt', response.data.token);

				// push them into the main dashboard
				this.$router.push('/switch');
			}
			else
			{
				if(!this.loginFail)
				{
					this.loginFail = `Login failed: ${response.data.reason}`;
					this.loginButton = 'Login';
				}
			}
		}
	}
});
