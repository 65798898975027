import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f43d92b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "epic"
}
const _hoisted_2 = { class: "epicInner" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "epicInfo" }
const _hoisted_5 = { class: "counts" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 3 }
const _hoisted_10 = { class: "epicContainer" }
const _hoisted_11 = {
  key: 1,
  class: "epicEmpty"
}
const _hoisted_12 = { class: "epicInner" }
const _hoisted_13 = { class: "emptyHeader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PTStory = _resolveComponent("PTStory")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['epicOuterContainer', this.overrideClass()])
  }, [
    (!this.isEmpty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.name), 1),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createTextVNode(_toDisplayString(_ctx.storyCount()) + " stor", 1),
                (_ctx.storyCount() > 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "ies"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_7, "y")),
                _createTextVNode(" , " + _toDisplayString(_ctx.pointCount()) + " point", 1),
                (_ctx.pointCount() == 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8))
                  : (_openBlock(), _createElementBlock("span", _hoisted_9, "s"))
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.filteredStories(), (story, index) => {
                return (_openBlock(), _createBlock(_component_PTStory, {
                  key: index,
                  story: story,
                  showEpic: this.showEpic
                }, null, 8, ["story", "showEpic"]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.name) + " - (0 Tickets)", 1)
          ])
        ]))
  ], 2))
}