
import { defineComponent } from 'vue';
import store from '@/store/store';
import { IObject, ReportObject } from '@/types';
import ProjectStatsTableEntry from '@/components/ProjectStatsTableEntry.vue';

export default defineComponent({
	name: 'ProjectRandDStatsReport',
	components: {
		ProjectStatsTableEntry
	},
	props: {
		team: {
			type: Object,
			required: true
		},
		title: {
			type: String,
			required: true
		}
	},
	computed: {
		areWeViewingAReport(): boolean
		{
			if(store.state.report && store.state.report.members && Object.keys(store.state.report.members).length > 0)
			{
				return true;
			}

			return false;
		},
		reportVersion(): number
		{
			if(this.areWeViewingAReport)
			{
				return store.state.report.version;
			}

			return 0;
		},
		reportMeta(): IObject
		{
			if(store.state.reportMeta)
			{
				return store.state.reportMeta;
			}

			return {};
		},
		report(): ReportObject|IObject
		{
			if(store.state.report && store.state.report.members && Object.keys(store.state.report.members).length > 0)
			{
				return store.state.report;
			}

			return {};
		}
	},
	methods: {
		// placeholder
		researchAndDevelopmentPercentage(memberTickets:IObject): string
		{
			const totalPoints = memberTickets.feature.points + memberTickets.bug.points + memberTickets.chore.points;

			// counting all dev work as R&D + x% of bug fix points
			const rndPoints = memberTickets.feature.points + (50 * (memberTickets.bug.points / 100));

			return `${Math.round((rndPoints / totalPoints) * 1000) / 10}%`;
		}
	},
	mounted()
	{
		// console.log('PROJ', this.project);
	}
});
